
.SVG-Icon{
  display: block; fill: rgb(255, 255, 255); height: 24px; width: 24px; stroke: rgb(34, 34, 34); stroke-width: 2; overflow: visible;
}
.HouseContainer{
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 330px;
  flex: 2 1 auto;
  margin: 40px;
}


.cardImage {
  float: left;
  max-height: 300px;
  border-radius: var(--rounding);
}
.cardImage:hover {
  box-shadow: 0px 10px 10px rgba(33,33,33,.1); 
}

.CardLine {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ChipBag {
  margin-top: 10px;
}