.wrapper-fullscreen {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

  padding: 10px 70px 6px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.wrapper-halfscreen {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 300px 6px 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 768px) {
  .wrapper-fullscreen {
  
    padding: 10px 30px 6px 30px !important;

  }
  
  
  
  .wrapper-halfscreen {

    padding: 10px 30px 6px 30px !important;

  }
}

svg {
  display: inline-block;
  vertical-align: top;
}


button + button {
  margin-left: 10px;
}

.NavLink {
  margin: 25px 30px 6px 30px;
  color: var(--theme-color);;
}