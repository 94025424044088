
:root {
  /* palette */

  --theme-color: #B6A99A;
  --highlight-color: #2A435B;
  --secondary-color: #EFEAE5;
  --gray-color: #b9b9b9;

  /* themes */

  --text-color: white;
  --secondary-text-color: var(--black-color);

  --image-placeholder-color: var(--light-gray-color);
  --rounding: 20px;
}

@import url("https://cdn.jsdelivr.net/npm/instantsearch.css@7.4.5/themes/satellite-min.css");

.heroimage {
  height: 745px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: -150px;

  z-index: -100;
  position: relative;
  }
  .thinsection {
    margin-top: 30px;
    margin-left: 300px;
    margin-right: 300px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  @media only screen and (max-width: 768px) {
  /* mobile adjustments */
    .noMobile {
      display: none;
    }


    .StreetBlock {

      justify-content: center !important;
      
    }


  }


  .herobox {
  
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: var(--secondary-color);

  }
  .herotext {
    font-family:'poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 96px;
    line-height: 144px;
    text-align: center;
    letter-spacing: 0.05em
  }

  .houseSection {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    
  }
.listheader {
  font-family: 'Poppins';
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 48px;
letter-spacing: 0.035em;
margin: 40px;
}

  .tagline {
    font-family: 'poppins';
font-style: 'light';
font-weight: 300;
font-size: 36px;
line-height: 54px;
text-align: center;
letter-spacing: 0.3em;

margin-bottom: 40px;
  }
    
    .brandedTitle {
      font-weight: 700;
      font-family: 'poppins';
      font-size: 36px;
      line-height: 0px;
      margin: 20px 0 6px 10px;
      display: inline-block;
      vertical-align: middle;
      color: var(--theme-color);
    }

  .houseName {
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 27px;
line-height: 32px;
/* identical to box height */


color: #000000;
  }
  .placeName {
    font-family: Roboto;
font-style: light;
font-weight: 300;
font-size: 20px;
line-height: 28px;

color: #000000;
  }

  .bAB{
    font-family: Roboto;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;

color: #000000;
  }

  .ownertag{
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;
  }

  .StreetBlock {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: left;
    align-content:space-between;
    margin: auto;
    width: 100%;
  }

  .Searchbox {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: left;
    align-content:space-between;
    margin: 10px;
    width: 100%;
  }
  .centrebox {

    display: flex;
    justify-content: center;
  }